<template>
  <div class="mx-3 mt-3">
    <div class="col-12 col-md-12 supply-table-wrapper">
      <supply-rule-widget-table />
    </div>
  </div>
</template>
<script>
// Components
import SupplyRuleWidgetTable from "./SupplyRule/partials/SupplyRuleWidgetTable";
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "element-ui";

export default {
  components: {
    SupplyRuleWidgetTable,
    [TableColumn.name]: TableColumn,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      tableData: [
        {
          page: "/argon/",
          visitors: "4,569",
          unique: "340",
          bounceRate: "46,53%",
          bounceRateDirection: "up",
        },
        {
          page: "/argon/index.html",
          visitors: "3,985",
          unique: "319",
          bounceRate: "46,53%",
          bounceRateDirection: "down",
        },
        {
          page: "/argon/charts.html",
          visitors: "3,513",
          unique: "294",
          bounceRate: "36,49%",
          bounceRateDirection: "down",
        },
        {
          page: "/argon/tables.html",
          visitors: "2,050",
          unique: "147",
          bounceRate: "50,87%",
          bounceRateDirection: "up",
        },
        {
          page: "/argon/profile.html",
          visitors: "1,795",
          unique: "190",
          bounceRate: "46,53%",
          bounceRateDirection: "down",
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.supply-table-wrapper {
  padding: 0;
  .supply-card {
    // max-width: 1000px;
    box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);
    border-radius: 1rem;
    overflow: hidden;
    margin: 0;
    @media only screen and (max-width: 991px) {
      border-radius: 12px;
    }
    .card-header {
      border-bottom: 1px solid#e9ecef !important;
      @media only screen and (max-width: 991px) {
        padding: 15px;
      }
    }
    .card-body {
      max-height: 350px;
      overflow-y: auto;
      padding-top: 15px;
      padding-bottom: 0;
      @media only screen and (max-width: 991px) {
        padding-left: 15px;
        padding-right: 15px;
      }
      .el-table {
        min-width: 100%;
        padding: 0 0 20px 0;
        .el-table__header-wrapper {
          min-width: 768px;
          table {
            .thead-light {
              th {
                background-color: transparent;
                border-top: none;
                border-bottom: none;
                color: #344767;
                font-size: 0.65rem !important;
              }
            }
          }
        }
        .el-table__body-wrapper {
          min-width: 768px;
          table {
            tbody {
              td {
                border-bottom: none;
                font-size: 0.75rem !important;
                color: #344767;
              }
            }
          }
        }
      }
    }
  }
  .supply-rule {
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &-text {
      width: 200px;
      @media only screen and (max-width: 768px) {
        width: 100%;
      }
    }
    &-filter {
      width: calc(100% - 350px);
      display: flex;
      flex-direction: row;
      @media only screen and (max-width: 768px) {
        width: 100%;
        margin: 10px 0 0 0;
      }
      span {
        flex: 1;
        padding: 0 20px 0 0;
        @media only screen and (max-width: 768px) {
          padding-right: 15px;
        }
        &:last-child {
          padding-right: 0;
        }
        .form-group {
          margin-bottom: 0;
        }
      }
    }
    &-button {
      width: 150px;
      padding: 0 0 0 20px;
      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin: 10px 0 0 0;
      }
      button {
        width: 100%;
        height: 43px;
        font-size: 14px;
        margin: 0;
      }
    }
  }
}
</style>
